"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _moment = _interopRequireDefault(require("moment"));
var _vuex = require("vuex");
var _duty = require("@/api/duty");
var _report = require("@/api/report");
var _user = require("@/api/user");
var _store = require("@/api/store");
var _elementUi = require("element-ui");
var _errorParse = require("@/utils/error-parse");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default
  },
  data() {
    return {
      list: null,
      listLoading: false,
      users: [],
      userOptions: [],
      stores: [],
      roles: [],
      listQuery: {
        isPublic: 'yes',
        fromTime: null,
        toTime: null,
        userId: null,
        storeId: null,
        roleId: null,
        page: 1,
        perPage: 10
      },
      result: {
        isIncludeFullTime: true,
        isIncludePartTime: true
      },
      total: 0
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user']),
    page: {
      get() {
        return this.listQuery.page;
      }
    },
    perPage: {
      get() {
        return this.listQuery.perPage;
      }
    },
    dutyDate: {
      get() {
        // console.log([this.listQuery.fromTime, this.listQuery.toTime])
        if (this.isIncludePartTime) {
          return [this.listQuery.fromTime, this.listQuery.toTime];
        } else {
          return this.listQuery.fromTime;
        }
      },
      set(secondDateOfWeek) {
        // console.log(secondDateOfWeek)
        if (this.isIncludePartTime) {
          if (secondDateOfWeek != null) {
            this.listQuery.fromTime = secondDateOfWeek[0];
            this.listQuery.toTime = secondDateOfWeek[1];
          } else {
            this.listQuery.fromTime = '';
            this.listQuery.toTime = '';
          }
        } else {
          if (secondDateOfWeek != null) {
            this.listQuery.fromTime = secondDateOfWeek;
            this.listQuery.toTime = (0, _moment.default)(secondDateOfWeek).endOf('month').format('YYYY-MM-DD');
          } else {
            this.listQuery.fromTime = '';
            this.listQuery.toTime = '';
          }
        }
      }
    },
    isIncludeFullTime: {
      get() {
        return !this.users.length || !this.listQuery.roleId && !this.listQuery.userId || [4, 5].indexOf(this.listQuery.roleId) !== -1 || this.listQuery.userId && this.users.filter(user => user.uuid === this.listQuery.userId)[0].roles.find(role => ['Staff (Full time)'].includes(role.name));
      }
    },
    isIncludePartTime: {
      get() {
        return !this.users.length || !this.listQuery.roleId && !this.listQuery.userId || this.listQuery.roleId === 9 || this.listQuery.userId && this.users.filter(user => user.uuid === this.listQuery.userId)[0].roles.find(role => ['Staff (Part time)'].includes(role.name));
      }
    }
  },
  watch: {
    page() {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.listQuery.page
        }
      });
    },
    perPage() {
      this.$router.push({
        query: {
          ...this.$route.query,
          perPage: this.listQuery.perPage
        }
      });
    }
  },
  async created() {
    const currentDate = new Date();
    if ((0, _moment.default)(currentDate).isoWeekday() >= 5) {
      this.listQuery['fromTime'] = (0, _moment.default)(currentDate).isoWeekday(5).format('YYYY-MM-DD');
      this.listQuery['toTime'] = (0, _moment.default)(currentDate).isoWeekday(11).format('YYYY-MM-DD');
    } else {
      this.listQuery['fromTime'] = (0, _moment.default)(currentDate).isoWeekday(-2).format('YYYY-MM-DD');
      this.listQuery['toTime'] = (0, _moment.default)(currentDate).isoWeekday(3).format('YYYY-MM-DD');
    }
    for (const key in this.listQuery) {
      if (this.$route.query[key]) {
        if (typeof this.listQuery[key] === 'number') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'roleId') {
          this.listQuery[key] = Number(this.$route.query[key]);
        } else if (key === 'fromTime' || key === 'toTime') {
          const date = new Date(this.$route.query[key]);
          // Check if the parsed date is valid
          if (isNaN(date)) {
            this.listQuery[key] = null;
          } else {
            const formattedDate = date.toISOString().split('T')[0];
            this.listQuery[key] = formattedDate; // Return the formatted date on success
          }
        } else {
          this.listQuery[key] = this.$route.query[key];
        }
      }
    }
    this.fetchData();
    if (window.requestAnimationFrame) {
      requestAnimationFrame(() => {
        this.fetchUsers();
        this.fetchStores();
        this.fetchRoles();
      });
    } else {
      this.$nextTick(() => {
        this.fetchUsers();
        this.fetchStores();
        this.fetchRoles();
      });
    }
  },
  methods: {
    async fetchRoles() {
      (0, _user.getRoles)({
        limit: 999
      }).then(response => {
        this.roles = response.data ? response.data.filter(row => ['Staff (Full time)', 'Staff (Part time)'].includes(row.name)) : [];
      });
    },
    async fetchUsers() {
      (0, _user.getUsers)({
        limit: 999,
        roles: ['staff.parttime', 'staff'],
        silentAlert: true
      }).then(response => {
        this.users = response.data;
        this.userOptions = response.data;
        this.result.isIncludePartTime = this.isIncludePartTime;
        this.result.isIncludeFullTime = this.isIncludeFullTime;
      }).catch(() => {});
    },
    async fetchStores() {
      (0, _store.getStores)({
        limit: 999,
        silentAlert: true
      }).then(response => {
        this.stores = response.data;
      }).catch(() => {});
    },
    userFilter(val) {
      this.value = val;
      if (val) {
        this.userOptions = this.users.filter(item => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true;
          }
        });
      } else {
        this.userOptions = this.users;
      }
    },
    query() {
      const param = {};
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = (0, _moment.default)(this.listQuery[key]);
          param[key] = current_datetime.format('YYYY-MM-DD'); // + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00')
        } else {
          param[key] = this.listQuery[key];
        }
      }
      this.$router.push({
        query: {
          ...this.listQuery,
          ...param
        }
      });
      this.fetchData();
    },
    downloadExcel() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      const param = {};
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = (0, _moment.default)(this.listQuery[key]);
          param[key] = current_datetime.format('YYYY-MM-DD') + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }
      (0, _duty.exportDutiesExcel)(param).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'duties.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadFullTime() {
      (0, _report.exportFullTimeSalary)({
        dateMonth: this.listQuery.fromTime,
        userId: this.listQuery.userId ? this.listQuery.userId : null
      }).then(response => {
        // console.log(response)
        // return
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `salary.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        console.error(err);
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || _i18n.default.t('PurchaseOrders.DownloadFailed'),
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    downloadBreakdown() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      const param = {};
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = (0, _moment.default)(this.listQuery[key]);
          param[key] = current_datetime.format('YYYY-MM-DD') + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }
      (0, _duty.exportDutiesSalaryBreakdown)(param).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'salary_breakdown.zip');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(err => {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(err) || 'Cannot Download the Report',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    downloadSummary() {
      (0, _elementUi.Message)({
        message: 'Preparing download file, please wait...',
        type: 'success',
        duration: 5 * 1000
      });
      this.fetchData();
      const param = {};
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if (key === 'fromTime' || key === 'toTime') {
          current_datetime = (0, _moment.default)(this.listQuery[key]);
          param[key] = current_datetime.format('YYYY-MM-DD') + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }
      (0, _duty.exportDutiesSalarySummary)(param).then(response => {
        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'salary_overview.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadPersonalSummary() {
      const param = this.listQuery;
      (0, _duty.personalSummary)(param).then(response => {
        // console.log(response);

        const fileURL = window.URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'personalSummary.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    fetchData() {
      const param = {
        filter: 'Y'
      };
      let current_datetime = new Date();
      for (const key in this.listQuery) {
        if (!this.listQuery[key]) {
          continue;
        }
        if ((key === 'fromTime' || key === 'toTime') && this.listQuery[key]) {
          current_datetime = (0, _moment.default)(this.listQuery[key]);
          param[key] = current_datetime.format('YYYY-MM-DD') + (key === 'toTime' ? ' 23:59:59' : ' 00:00:00');
        } else if (this.listQuery[key] === 'yes') {
          param[key] = 1;
        } else if (this.listQuery[key] === 'no') {
          param[key] = 0;
        } else {
          param[key] = this.listQuery[key];
        }
      }
      this.listLoading = true;
      return (0, _duty.getDuties)(param).then(response => {
        this.list = response.data;
        if (Array.isArray(response.data)) {
          this.list.forEach((duty, index) => {
            duty.id = index + response.meta.from;
          });
          this.total = response.meta.total;
          if (this.users.length) {
            this.result.isIncludePartTime = this.isIncludePartTime;
            this.result.isIncludeFullTime = this.isIncludeFullTime;
          }
        }
      }).finally(() => {
        this.listLoading = false;
      });
    },
    disabledDate(time) {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return time.getTime() > yesterday;
    }
  }
};
exports.default = _default;