"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _expense = require("@/api/expense");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loading: false,
      step: '00:05',
      expense: {
        id: null,
        name: ''
      },
      buttonText: 'Add',
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        }
      },
      isEdit: false
    };
  },
  computed: {
    device() {
      return this.$store.state.app.device;
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.buttonText = 'Edit';
      this.isEdit = true;
      this.fetchData();
    } else {
      this.buttonText = 'Add';
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      (0, _expense.getExpense)(this.$route.params.id).then(response => {
        this.expense = response.data;
      }).catch(error => console.error(error)).finally(() => {
        this.loading = false;
      });
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          const param = {
            name: this.expense.name
          };
          this.loading = true;
          if (this.$route.params.id) {
            param.id = this.$route.params.id;
            (0, _expense.editExpense)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Expense updated.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/expenses`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
            }).finally(() => {
              this.loading = false;
            });
          } else {
            (0, _expense.createExpense)(param).then(() => {
              (0, _elementUi.Message)({
                message: 'Expense created.',
                type: 'success',
                duration: 5 * 1000
              });
              this.$router.push(`/expenses`);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            }).finally(() => {
              this.loading = false;
            });
          }
        }
      });
    }
  }
};
exports.default = _default;