"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _errorParse = require("@/utils/error-parse");
var _duty = require("@/api/duty");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _shift = require("@/api/shift");
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
var _vueSelect = _interopRequireDefault(require("vue-select"));
require("vue-select/src/scss/vue-select.scss");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DutyDialog',
  components: {
    vSelect: _vueSelect.default
  },
  props: {
    newDuty: {
      type: Object,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minTime: '00:00',
      maxTime: '23:55',
      step: '00:05',
      role: null,
      shift: {
        uuid: null,
        name: null,
        id: null
      },
      dutyForm: {
        roleId: null,
        name: null,
        fromTime: null,
        nextDay: false,
        toTime: null
      },
      shifts: [],
      shiftFetchOptions: {
        page: 1
      },
      shiftToShow: 0,
      shiftResponse: [],
      roles: [],
      saveAsShift: false,
      showShiftCheckbox: true,
      loading: false,
      rules: {
        name: {
          required: true,
          message: 'Name is required'
        },
        fromTime: {
          required: true,
          message: 'Start time is required'
        },
        toTime: {
          required: true,
          message: 'End time is required'
        },
        roleId: {
          required: true,
          message: 'Role is required',
          trigger: ['blur', 'change']
        }
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user']),
    addTitle: {
      get() {
        return (this.newDuty.uuid === undefined ? 'Create Duty on ' : 'Edit Duty on ') + this.newDuty.date;
      }
    },
    buttonText: {
      get() {
        return this.newDuty.uuid === undefined ? 'Add' : 'Edit';
      }
    },
    nameDisabled: {
      get() {
        return this.newDuty.userId !== undefined;
      }
    },
    userShifts: {
      get() {
        let shifts = null;
        if (this.newDuty.userId === null) {
          shifts = this.shifts;
        }
        shifts = this.shifts;
        if (this.dutyForm.roleId) {
          shifts = this.shifts.filter(item => item.role.id === this.dutyForm.roleId);
        } else {
          shifts = this.shifts;
        }
        return shifts;
      }
    },
    userRoles: {
      get() {
        let roles = [];
        if (this.newDuty.user && this.newDuty.user.roles) {
          roles = this.newDuty.user.roles.filter(role => role.is_duty_role);
        }
        return roles;
      }
    },
    visible: {
      get() {
        return this.dialogFormVisible;
      },
      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }
    },
    hasNextPage() {
      return this.shifts.length < this.shiftToShow;
    }
  },
  watch: {
    newDuty(newDuty, oldVal) {
      /* if (this.shiftResponse.length) {
        this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id))
      } else {
        getShifts({ limit: 999 }).then(res => {
          this.shiftResponse = res.data
          this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id))
        })
      } */

      // const roleResponse = await getRoles()
      this.roles = this.newDuty.user.roles;
      this.dutyForm = {
        ...this.dutyForm,
        ...this.newDuty
      };
      if (this.dutyForm.shiftId) {
        this.dutyForm.shiftId = undefined;
      }
      if (this.dutyForm.fromTime && this.dutyForm.toTime) {
        const start = (0, _moment.default)(this.dutyForm.fromTime);
        this.dutyForm.fromTime = start.format('HH:mm');
        const end = (0, _moment.default)(this.dutyForm.toTime);
        this.dutyForm.toTime = end.format('HH:mm');
        if (start.format('D') < end.format('D')) {
          this.dutyForm.nextDay = true;
        }
      } else {
        if (this.dutyForm.fromTime) {
          const start = (0, _moment.default)(this.dutyForm.fromTime);
          this.dutyForm.fromTime = start.format('HH:mm');
        }
        if (this.dutyForm.toTime) {
          const end = (0, _moment.default)(this.dutyForm.toTime);
          this.dutyForm.toTime = end.format('HH:mm');
        }
      }
      this.showMatchingShift();
      this.dutyForm.roleId = null;
      if (this.newDuty.roleId) {
        this.dutyForm.roleId = this.newDuty.roleId;
      }
      if (this.newDuty.uuid) {
        this.showShiftCheckbox = false;
        this.saveAsShift = false;
      }
      if (!this.dutyForm.roleId && this.shiftResponse && newDuty.user.roles && !oldVal.user.roles) {
        // console.log('getShifts in watch', this.dutyForm.roleId, this.shiftFetchOptions.page, this.newDuty.user.roles, this.shiftResponse.length, this.shifts.length, this.shiftToShow)
        this.shifts = this.shiftResponse.filter(shift => newDuty.user.roles.find(role => role.id === shift.role.id));
        if (this.shifts.length < 1) {
          this.shiftFetchOptions.page = 1;
          this.fetchShift();
          // console.log('getShifts in watch after fetchShift', this.shift.length, this.shiftToShow)
        }
      }

      if (this.shiftResponse && newDuty.user.roles && oldVal.user.roles && newDuty.user.roles.reduce((prev, curr) => prev.id + curr.id, 0) !== oldVal.user.roles.reduce((prev, curr) => prev.id + curr.id, 0)) {
        this.shiftFetchOptions.page = 1;
        this.fetchShift();
        // console.log('getShifts in watch on changing roles', this.shift.length, this.shiftToShow)
      }
    },

    /* 'dutyForm.shiftId': function(newShiftId, oldShiftId) {
      // Watcher callback function
      console.log('Shift ID changed:', newShiftId)
      this.onShiftSelected()
      // Perform any desired actions or computations based on the new shift ID
    }, */
    saveAsShift() {
      if (this.dutyForm.roleId == null) {
        return;
      }
      const roles = this.roles.filter(item => {
        if (item.id === this.dutyForm.roleId) return true;
      });
      if (roles.length === 0) {
        return;
      }
      let roleName = roles[0].name;
      if (this.dutyForm.fromTime != null && this.dutyForm.toTime != null) {
        roleName = roleName + ' (' + this.dutyForm.fromTime + ' to ' + this.dutyForm.toTime + ')';
      }
      this.shift.name = roleName;
    },
    userRoles() {
      if (this.userRoles && this.userRoles.length === 1 && this.userRoles[0] && !this.dutyForm.roleId) {
        this.dutyForm.roleId = this.userRoles[0].id;
      }
    }
  },
  async created() {
    // this.shiftResponse = []
    // console.log('created')
    this.fetchShift();
  },
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    // console.log('mounted')
    this.observer = new IntersectionObserver(this.infiniteScroll);
    if (this.newDuty.uuid) {
      this.roles = this.newDuty.user.roles;
      this.dutyForm = {
        ...this.dutyForm,
        ...this.newDuty
      };
      if (this.dutyForm.shiftId) {
        this.dutyForm.shiftId = undefined;
      }
      if (this.dutyForm.fromTime && this.dutyForm.toTime) {
        const start = (0, _moment.default)(this.dutyForm.fromTime);
        this.dutyForm.fromTime = start.format('HH:mm');
        const end = (0, _moment.default)(this.dutyForm.toTime);
        this.dutyForm.toTime = end.format('HH:mm');
        if (start.format('D') < end.format('D')) {
          this.dutyForm.nextDay = true;
        }
      } else {
        if (this.dutyForm.fromTime) {
          const start = (0, _moment.default)(this.dutyForm.fromTime);
          this.dutyForm.fromTime = start.format('HH:mm');
        }
        if (this.dutyForm.toTime) {
          const end = (0, _moment.default)(this.dutyForm.toTime);
          this.dutyForm.toTime = end.format('HH:mm');
        }
      }
      this.showMatchingShift();
      this.showShiftCheckbox = false;
      this.saveAsShift = false;
    }
    this.dutyForm.roleId = null;
    if (this.newDuty.roleId) {
      this.dutyForm.roleId = this.newDuty.roleId;
    } else if (this.userRoles.length === 1) {
      this.dutyForm.roleId = this.userRoles[0].id;
    }
  },
  methods: {
    async fetchShift(keyword = '', loading = null) {
      if (loading) {
        loading(true);
        this.observer.disconnect();
        this.shiftFetchOptions = {
          ...this.shiftFetchOptions,
          roleId: this.dutyForm.roleId,
          page: 1,
          keyword
        };
      } else {
        this.shiftFetchOptions = {
          ...this.shiftFetchOptions,
          roleId: this.dutyForm.roleId,
          keyword
        };
      }
      if (!this.dutyForm.roleId && this.newDuty.user.roles) {
        this.shiftFetchOptions.roleId = this.newDuty.user.roles.map(item => item.id);
      }
      return (0, _shift.getShifts)(this.shiftFetchOptions).then(response => {
        this.shiftToShow = response.meta.total;
        if (loading) {
          this.shiftResponse = this.shiftResponse.concat(response.data);
          this.shiftResponse = this.shiftResponse.reduce((result, obj) => {
            if (!result.find(item => item.uuid === obj.uuid)) {
              result.push(obj);
            }
            return result;
          }, []);
          if (this.dutyForm.roleId) {
            this.shifts = this.shiftResponse.filter(shift => shift.role.id === this.dutyForm.roleId);
          } else if (this.newDuty.user.roles) {
            this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id));
          }
          this.onOpen();
        } else {
          // console.log('getShifts in fetchShift', this.dutyForm.roleId, this.shiftFetchOptions.page, this.newDuty.user.roles, this.shiftResponse.length, this.shifts.length, this.shiftToShow)
          this.shiftResponse = this.shiftResponse.concat(response.data);
          this.shiftResponse = this.shiftResponse.reduce((result, obj) => {
            if (!result.find(item => item.uuid === obj.uuid)) {
              result.push(obj);
            }
            return result;
          }, []);
          if (this.dutyForm.roleId) {
            this.shifts = this.shiftResponse.filter(shift => shift.role.id === this.dutyForm.roleId);
          } else if (this.newDuty.user.roles) {
            this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id));
          } else {
            this.shifts = this.shiftResponse;
          }
          // console.log('check shifts length', this.shifts.length)
        }
      }).catch(error => console.error(error)).finally(() => {
        if (loading) {
          loading(false);
        }
      });
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        if (this.$refs.load !== undefined) {
          this.observer.observe(this.$refs.load);
        }
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{
      isIntersecting,
      target
    }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.shiftFetchOptions.page++;
        await this.fetchShift();
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.createUpdateDuty();
        }
      });
    },
    onShiftSelected() {
      // console.log('onShiftSelected')
      if (this.dutyForm.shiftId == null || this.dutyForm.shiftId === '') {
        return;
      }
      const shift = this.shifts.filter(item => {
        if (item.uuid === this.dutyForm.shiftId) return true;
      })[0];
      this.dutyForm.roleId = shift.role.id;
      this.dutyForm.fromTime = (shift.from_hour > 9 ? '' : '0') + parseInt(shift.from_hour) + ':' + (shift.from_min > 9 ? '' : '0') + parseInt(shift.from_min);
      this.dutyForm.toTime = (shift.to_hour > 9 ? '' : '0') + parseInt(shift.to_hour) + ':' + (shift.to_min > 9 ? '' : '0') + parseInt(shift.to_min);
      this.showShiftCheckbox = false;
      this.saveAsShift = false;
      if (shift.from_hour > shift.to_hour) {
        this.dutyForm.nextDay = true;
      }
    },
    async onRoleSelected() {
      if (this.dutyForm.shiftId) {
        this.dutyForm.shiftId = null;
      }
      this.shiftFetchOptions.page = 1;
      await this.fetchShift();
      this.showMatchingShift();
    },
    onStartTimeSelected() {
      this.showMatchingShift();
    },
    onEndTimeSelected() {
      this.showMatchingShift();
    },
    async showMatchingShift() {
      if (this.newDuty == null || this.dutyForm.fromTime == null || this.dutyForm.toTime == null) {
        if (this.dutyForm.shiftId) {
          this.dutyForm.shiftId = null;
        }
        this.shift.name = null;
        this.shift.id = null;
        if (!this.newDuty.uuid) {
          this.showShiftCheckbox = true;
        }
        return;
      }
      const start = this.dutyForm.fromTime.split(':');
      const end = this.dutyForm.toTime.split(':');
      if (this.dutyForm.roleId) {
        this.shifts = this.shiftResponse.filter(shift => shift.role.id === this.dutyForm.roleId);
      } else if (this.newDuty.user.roles) {
        this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id));
      }
      const matched = this.shifts.filter(item => {
        if (parseInt(item.from_hour) === parseInt(start[0]) && parseInt(item.from_min) === parseInt(start[1]) && parseInt(item.to_hour) === parseInt(end[0]) && parseInt(item.to_min) === parseInt(end[1]) && item.role.id === this.dutyForm.roleId) {
          return item;
        }
      });
      if (matched.length > 0) {
        // console.log('direct found')
        this.dutyForm.shiftId = matched[0].uuid;
        this.shift.name = matched[0].name;
        this.shift.id = matched[0].id;
        this.showShiftCheckbox = false;
        this.saveAsShift = false;
      } else {
        // console.log('goto online search')
        // server side search and append to shifts
        const response = await (0, _shift.getShifts)({
          fromHour: start[0],
          fromMin: start[1],
          toHour: end[0],
          toMin: end[1],
          roleId: this.dutyForm.roleId
        });
        if (response.data && Array.isArray(response.data) && response.data.length) {
          this.shiftResponse = this.shiftResponse.concat(response.data);
          this.shiftResponse = this.shiftResponse.reduce((result, obj) => {
            if (!result.find(item => item.uuid === obj.uuid)) {
              result.push(obj);
            }
            return result;
          }, []);
          if (this.dutyForm.roleId) {
            this.shifts = this.shiftResponse.filter(shift => shift.role.id === this.dutyForm.roleId);
          } else if (this.newDuty.user.roles) {
            this.shifts = this.shiftResponse.filter(shift => this.newDuty.user.roles.find(role => role.id === shift.role.id));
          }
          const matched = this.shiftResponse.filter(item => {
            if (parseInt(item.from_hour) === parseInt(start[0]) && parseInt(item.from_min) === parseInt(start[1]) && parseInt(item.to_hour) === parseInt(end[0]) && parseInt(item.to_min) === parseInt(end[1]) && item.role.id === this.dutyForm.roleId) {
              return item;
            }
          });
          if (matched.length > 0) {
            this.dutyForm.shiftId = matched[0].uuid;
            this.shift.name = matched[0].name;
            this.shift.id = matched[0].id;
            this.showShiftCheckbox = false;
            this.saveAsShift = false;
            return;
          }
        }
        if (this.dutyForm.shiftId) {
          this.dutyForm.shiftId = null;
        }
        this.shift.name = null;
        this.shift.id = null;
        if (!this.newDuty.uuid) {
          this.showShiftCheckbox = true;
        }
      }
    },
    calculateHour(fromTime, toTime, nextDay) {
      const start = (0, _moment.default)();
      const [fromHour, fromMin] = fromTime.split(':');
      start.set({
        hour: fromHour,
        minute: fromMin
      });
      const end = (0, _moment.default)();
      const [toHour, toMin] = toTime.split(':');
      end.set({
        hour: toHour,
        minute: toMin
      });
      if (nextDay) {
        end.add(1, 'days');
      }
      return _moment.default.duration(end.diff(start)).asHours();
    },
    submitUpdateDuty() {
      const start = this.dutyForm.fromTime.split(':');
      const end = this.dutyForm.toTime.split(':');
      // this.newDuty.roleId = this.dutyForm.roleId
      const param = {
        date: this.newDuty.date,
        userId: this.newDuty.userId,
        storeId: this.$route.params.id,
        fromHour: start[0],
        fromMin: start[1],
        toHour: end[0],
        toMin: end[1],
        roleId: this.dutyForm.roleId,
        nextDay: this.dutyForm.nextDay
      };
      if (this.dutyForm.shiftId !== undefined) {
        param.shiftId = this.dutyForm.shiftId;
      }
      this.loading = true;
      if (this.saveAsShift === true) {
        return this.saveShift(param).then(() => {
          if (this.newDuty.uuid) {
            param.id = this.newDuty.uuid;
            return (0, _duty.editDuty)(param).then(response => {
              (0, _elementUi.Message)({
                message: 'Duty updated.',
                type: 'success',
                duration: 5 * 1000
              });
              const duty = response.data;
              this.$emit('complete', duty);
              this.$emit('update:dialogFormVisible', false);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
              // console.log(err)
            });
          } else {
            return (0, _duty.createDuty)(param).then(response => {
              (0, _elementUi.Message)({
                message: 'Duty created.',
                type: 'success',
                duration: 5 * 1000
              });
              const duty = response.data;
              this.$emit('complete', duty);
              this.$emit('update:dialogFormVisible', false);
            }).catch(err => {
              (0, _elementUi.Message)({
                dangerouslyUseHTMLString: true,
                message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
                type: 'error',
                duration: 5 * 1000
              });
            });
          }
        }).finally(() => {
          this.loading = false;
        });
      } else if (this.newDuty.uuid) {
        param.id = this.newDuty.uuid;
        return (0, _duty.editDuty)(param).then(response => {
          (0, _elementUi.Message)({
            message: 'Duty updated.',
            type: 'success',
            duration: 5 * 1000
          });
          const duty = response.data;
          this.$emit('complete', duty);
          this.$emit('update:dialogFormVisible', false);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          // console.log(err)
        }).finally(() => {
          this.loading = false;
        });
      } else {
        return (0, _duty.createDuty)(param).then(response => {
          (0, _elementUi.Message)({
            message: 'Duty created.',
            type: 'success',
            duration: 5 * 1000
          });
          const duty = response.data;
          this.$emit('complete', duty);
          this.$emit('update:dialogFormVisible', false);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    createUpdateDuty() {
      if (this.newDuty.user.workingHoursPerDay && this.newDuty.user.roles.find(role => ['Staff (Full time)'].includes(role.name)) && this.calculateHour(this.dutyForm.fromTime, this.dutyForm.toTime, this.dutyForm.nextDay) !== this.newDuty.user.workingHoursPerDay) {
        return _elementUi.MessageBox.confirm('Duration is not same like preset user working hour, it may make salary calculation for half-day leave inaccurate, Are you sure want to continue ?', 'Confirm', {
          confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
          cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
          type: 'warning'
        }).then(this.submitUpdateDuty).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
      } else {
        return this.submitUpdateDuty();
      }
    },
    saveShift(duty) {
      const name = this.shift.name;
      const param = {
        fromHour: duty.fromHour,
        fromMin: duty.fromMin,
        toHour: duty.toHour,
        toMin: duty.toMin,
        name: name,
        roleId: duty.roleId,
        nextDay: this.dutyForm.nextDay
      };
      return (0, _shift.createShift)(param).then(response => {
        (0, _elementUi.Message)({
          message: 'Shift created.',
          type: 'success',
          duration: 5 * 1000
        });
        this.shifts.push(response.data);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    toggleNextDate() {
      this.dutyForm.toTime = '';
    }
  }
};
exports.default = _default;