var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "action-container" },
        [
          _c(
            "router-link",
            {
              staticClass: "el-button el-button--primary",
              class: { "is-disabled": _vm.isOffline },
              attrs: { to: _vm.isOnline ? "/expenses/add" : "#" },
            },
            [
              _c("i", { staticClass: "el-icon-circle-plus" }),
              _vm._v(" Add Expense"),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "95" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.id) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Name") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        " + _vm._s(scope.row.name) + "\n      "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Order.Actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "el-button el-button--warning is-plain",
                        class: { "el-button--mini": _vm.device === "mobile" },
                        attrs: { to: "/expenses/edit/" + scope.row.uuid },
                      },
                      [
                        _c("i", { staticClass: "el-icon-edit" }),
                        _vm._v(" " + _vm._s(_vm.$t("Order.Edit"))),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          disabled: _vm.isOffline,
                          size: _vm.device === "mobile" ? "mini" : "default",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteExpense(scope.row.uuid)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Order.Delete")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }