"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _errorParse = require("@/utils/error-parse");
var _vuex = require("vuex");
var _store = require("@/api/store");
var _duty = require("@/api/duty");
var _rosterConfirmation = require("@/api/rosterConfirmation");
var _user = require("@/api/user");
var _leave = require("@/api/leave");
var _elementUi = require("element-ui");
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import $ from 'jquery'
// import DutyDialog from './duty-dialog'
// import LeaveDialog from './leave-dialog'
// import axios from 'axios'

const DutyDialog = () => ({
  component: Promise.resolve().then(() => _interopRequireWildcard(require('./duty-dialog'))),
  loading: {
    template: `<h1>LOADING</h1>`
  }
});
const LeaveDialog = () => ({
  component: Promise.resolve().then(() => _interopRequireWildcard(require('./leave-dialog'))),
  loading: {
    template: `<h1>LOADING</h1>`
  }
});
var _default = {
  components: {
    DutyDialog,
    LeaveDialog
  },
  beforeRouteLeave(to, from, next) {
    this.cancelTokenFetch.abort();
    next();
  },
  data() {
    return {
      search: null,
      tableHeight: 400,
      week: null,
      duplicateWeek: null,
      selectedOptions: null,
      dragSource: null,
      days: [],
      pickerOptions: {
        firstDayOfWeek: 1
      },
      duplicateOptions: {
        firstDayOfWeek: 1
      },
      // list: null,
      listLoading: true,
      /* listQuery: {
        page: 1,
        perPage: 999
      }, */
      // total: 0,
      users: [],
      groupedUsers: [],
      duties: [],
      responseduties: [],
      leaves: [],
      rosterConfirmations: [],
      dialogFormVisible: false,
      keepAliveDialogFormVisible: false,
      dialogLeaveFormVisible: false,
      keepAliveDialogLeaveFormVisible: false,
      storeId: null,
      newDuty: {
        id: null,
        name: '',
        user: {
          name: ''
        },
        fromTime: null,
        toTime: null,
        roleId: null
      },
      newLeavehistory: {
        id: 0,
        leave_id: null,
        user_id: null,
        name: '',
        user: {
          name: ''
        },
        leaves: [],
        datefrom: '',
        dateto: '',
        timefrom: '',
        timeto: '',
        periodfrom: 'AM',
        periodto: 'AM',
        total: null,
        status: 'pending',
        remark: ''
      },
      userStorted: false,
      storeSales: [],
      store: null,
      hasUnpublish: false,
      defaultDuplicateDatePicker: null,
      dutyLastUpdatedAt: '',
      dutyLastUpdatedBy: '',
      leaveApplication: _i18n.default.t('route.LeaveApplication'),
      cancelTokenFetch: new AbortController()
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)([
    // 'name',
    'user']),
    dateTitle: {
      get() {
        if (!this.days.length) {
          return '';
        }
        return new Date(this.days[0]).toString().slice(4, 10) + ' - ' + new Date(this.days[6]).toString().slice(4, 15);
      }
    },
    isPast: {
      get() {
        return this.days[6] && (0, _moment.default)().diff(this.days[6], 'days') > 0;
      }
    },
    today: {
      get() {
        const today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
      }
    },
    filteredUsers: {
      get() {
        // console.log('in')
        let users = this.search ? this.groupedUsers.map(group => {
          const newgroup = {
            ...group
          };
          newgroup.children = newgroup.children.filter(user => user.name.includes(this.search));
          return newgroup;
        }) : this.groupedUsers;
        if (this.rosterConfirmations && this.rosterConfirmations.length) {
          users = users.map(group => {
            const newgroup = {
              ...group
            };
            newgroup.children = newgroup.children.filter(user => this.duties.find(duty => duty.user.uuid === user.uuid) /*  || this.leaves.find(leave => leave.user.uuid === user.uuid) */);
            return newgroup;
          });
        }

        // console.log('in2', users, users.filter(group => group.children.length))
        // console.log(this.users, this.groupedUsers)
        return users.filter(group => group.children.length);
      }
    }
  },
  watch: {
    week: function (val) {
      this.listLoading = true;
      this.pickedWeek(val);
    },
    duties: {
      handler: function (duties) {
        const addUsers = [];
        duties.map(duty => {
          if (this.users.findIndex(user => user.uuid === duty.user.uuid) === -1 && addUsers.findIndex(user => user.uuid === duty.user.uuid) === -1) {
            addUsers.push(duty.user);
          }
          return duty;
        });
        this.users = this.users.concat(addUsers);
        const manager = this.users.filter(user => user.roles.findIndex(role => role.name === 'Store Manager') !== -1);
        manager.sort((a, b) => {
          if (b.roles.findIndex(role => role.name === 'Staff (Part time)') !== -1 && a.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1) {
            return -1; // "free" comes before "full"
          } else if (a.roles.findIndex(role => role.name === 'Staff (Part time)') !== -1 && b.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1) {
            return 1; // "full" comes before "free"
          } else {
            return 0; // no change in order
          }
        });

        const nonManager = this.users.filter(user => user.roles.findIndex(role => role.name === 'Store Manager') === -1);
        const fullTimer = nonManager.filter(user => {
          const dutyCheck = duties.find(duty => duty.user.uuid === user.uuid);
          return dutyCheck && dutyCheck.role.name === 'Staff (Full time)' || !user.roles.find(role => ['Staff (Part time)', 'Store Manager'].includes(role.name));
        });
        const partTimer = nonManager.filter(user => {
          const dutyCheck = duties.find(duty => duty.user.uuid === user.uuid);
          return (!dutyCheck || dutyCheck.role.name !== 'Staff (Full time)') && user.roles.findIndex(role => role.name === 'Staff (Part time)') !== -1;
        });
        const key = Date.now();
        this.groupedUsers = (manager.length ? [{
          group: true,
          uuid: key + '1',
          name: 'Store Manager',
          children: manager
        }] : []).concat(fullTimer.length ? [{
          group: true,
          uuid: key + '2',
          name: 'Staff (Full time)',
          children: fullTimer
        }] : []).concat(partTimer.length ? [{
          group: true,
          uuid: key + '3',
          name: 'Staff (Part time)',
          children: partTimer
        }] : []);

        // this.listLoading = false
        if (this.user.stores.length && !this.store) {
          const selectedStore = this.user.stores.filter(store => store.uuid === this.storeId);
          if (selectedStore.length) {
            this.store = selectedStore[0];
          }
        }

        // console.log({ fullTimer, partTimer, nonManager, groupedUsers: this.groupedUsers }, { duties, oldVal })
      },

      deep: true
    },
    dialogFormVisible: function (val) {
      if (val && !this.keepAliveDialogFormVisible) {
        this.keepAliveDialogFormVisible = true;
      }
    },
    dialogLeaveFormVisible: function (val) {
      if (val && !this.keepAliveDialogLeaveFormVisible) {
        this.keepAliveDialogLeaveFormVisible = true;
      }
    }
  },
  async created() {
    this.storeId = this.$route.params.id;
    this.tableHeight = document.body.clientHeight - 200;
    window.onresize = () => {
      this.tableHeight = document.body.clientHeight - 200;
    };
    this.defaultDuplicateDatePicker = (0, _moment.default)(this.days[0]).subtract(1, 'days').format('YYYY-MM-DD');
    this.getCurrentWeek();
  },
  methods: {
    async getUsers(refreshing = false) {
      const params = {
        active: 'true',
        storeId: this.storeId,
        limit: 999,
        roles: ['staff.parttime', 'staff'],
        fromTime: this.days[0],
        toTime: this.days[6],
        callFrom: 'roster',
        signal: this.cancelTokenFetch.signal
      };
      if (refreshing) {
        (0, _user.getUsers)({
          ...params
        });
        params.hash = Math.floor(Math.random() * 100) + 1;
      }
      return (0, _user.getUsers)(params).then(response => {
        if (!response) {
          return;
        }
        this.users = response.data.filter(user => {
          return (/* !user.roles.find(role => role.name === 'Admin') && */user.roles.find(role => ['Staff (Full time)', 'Staff (Part time)'].includes(role.name))
          );
        });
        // console.log('call $forceUpdate')
        /* this.$nextTick().then(() => {
          this.$forceUpdate()
        }) */
        setTimeout(() => {
          if (this.$refs.rosterTable) {
            this.$refs.rosterTable.doLayout();
          }
        }, 300);
      });
    },
    async fetchData(refreshing = false, promise = null) {
      this.dutyLastUpdatedAt = '';
      this.dutyLastUpdatedBy = '';
      if (!this.$workbox && refreshing) {
        refreshing = false;
      }
      const rosterParams = {
        storeId: this.storeId,
        fromTime: this.days[0],
        toTime: this.days[6]
      };
      rosterParams.signal = this.cancelTokenFetch.signal;
      if (refreshing) {
        (0, _rosterConfirmation.getRosterConfirmations)({
          ...rosterParams
        });
        rosterParams.hash = Math.floor(Math.random() * 100) + 1;
      }

      // console.log('getRosterConfirmations')
      await (0, _rosterConfirmation.getRosterConfirmations)(rosterParams).then(response => {
        if (!response) {
          return;
        }
        this.rosterConfirmations = response.data;
        if (this.rosterConfirmations.length && !this.store) {
          this.store = this.rosterConfirmations[0].store;
        }
        const leavesParams = {
          fromTime: this.days[0],
          toTime: this.days[6] + ' 23:59:59',
          storeId: this.storeId,
          sorting: 'asc',
          listAll: true,
          leaveHistoryStatus: ['approved', 'pending'],
          perPage: 9999,
          signal: this.cancelTokenFetch.signal
        };
        if (refreshing) {
          (0, _leave.getHistory)({
            ...leavesParams
          });
          leavesParams.hash = Math.floor(Math.random() * 100) + 1;
        }
        (0, _leave.getHistory)(leavesParams).then(response => {
          if (!response) {
            return;
          }
          this.leaves = response.data;
          let status = ['approved'];
          if (this.rosterConfirmations && !this.rosterConfirmations.find(rosterConfirmation => rosterConfirmation.status === 'confirmed')) {
            status = ['pending'];
            if (this.user.roles.find(role => ['Operation Manager', 'Admin'].includes(role.name))) {
              status.push('expired');
            }
          }
          this.leaves = this.leaves.filter(history => history.leave.refill_period === 'week' || status.indexOf(history.status) !== false).map(item => {
            item.name = item.user.uuid;
            return item;
          });
        }).catch(err => {
          console.error(err);
        });
      });
      const dutiesParams = {
        is_related: 'true',
        storeId: this.storeId,
        perPage: 9999,
        fromTime: this.days[0],
        toTime: (0, _moment.default)(this.days[6]).add(1, 'days').format('YYYY-MM-DD') + ' 02:00:00',
        signal: this.cancelTokenFetch.signal
      };
      if (refreshing) {
        (0, _duty.getDuties)({
          ...dutiesParams
        });
        dutiesParams.hash = Math.floor(Math.random() * 100) + 1;
      }
      this.listLoading = true;
      // console.log('getDuties')

      if (promise) {
        await promise.then(async () => {
          await (0, _duty.getDuties)(dutiesParams).then(response => {
            if (!response) {
              return;
            }
            this.duties = response.data;
            if (this.duties.length) {
              this.duties = this.duties.map(item => {
                item.name = item.user.uuid;
                return item;
              });
            }
            this.responseduties = this.duties;
            if (this.duties.length && !this.store) {
              // const cleanduty = this.duties.find(duties => duties.is_related !== true)
              if (response.store) {
                this.store = response.store; // @TODO get from same numbered
              } else {
                const cleanduty = this.duties.find(duties => duties.is_related !== true);
                if (cleanduty.store) {
                  this.store = cleanduty.store; // @TODO get from same numbered
                }
              }
            }

            if (this.duties.length) {
              this.duties = this.duties.filter(duty => duty.store.uuid === this.storeId);
            }
            this.hasUnpublish = false;
            this.getLastUpdateInfo();
            // console.log('getDuties done')
          }).finally(() => {
            this.listLoading = false;
          });
        });
      } else {
        await (0, _duty.getDuties)(dutiesParams).then(response => {
          if (!response) {
            return;
          }
          this.duties = response.data;
          if (this.duties.length) {
            this.duties = this.duties.map(item => {
              item.name = item.user.uuid;
              return item;
            });
          }
          this.responseduties = this.duties;
          if (this.duties.length) {
            // const cleanduty = this.duties.find(duties => duties.is_related !== true)
            if (response.store) {
              this.store = response.store; // @TODO get from same numbered
            } else {
              const cleanduty = this.duties.find(duties => duties.is_related !== true);
              if (cleanduty.store) {
                this.store = cleanduty.store; // @TODO get from same numbered
              }
            }
          }

          if (this.duties.length && this.store) {
            this.duties = this.duties.filter(duty => duty.store.uuid === this.storeId);
          }
          this.hasUnpublish = false;
          this.getLastUpdateInfo();
          // console.log('getDuties done')
        }).finally(() => {
          // console.log('getDuties catch')
          this.listLoading = false;
        });
      }

      // console.log('listLoading false')

      // this.listLoading = false
      // console.log('storeSlaes []')
      this.storeSales = [];
      // console.log('getStoreSales')
      (0, _store.getStoreSales)(this.storeId, {
        fromTime: this.days[0],
        toTime: this.days[6] + ' 23:59:59',
        groupBy: 'date',
        signal: this.cancelTokenFetch.signal
      }).then(response => {
        if (!response) {
          return;
        }
        this.storeSales = response.data;
        this.footerSummary();
        setTimeout(() => {
          if (this.$refs.rosterTable) {
            this.$refs.rosterTable.doLayout();
          }
        }, 300);
        // this.footerSummary()
      });
    },

    getLastUpdateInfo() {
      if (this.duties.length > 0) {
        let dutyLastUpdatedAt = this.duties[0].last_updated_at;
        let dutyLastUpdatedBy = this.duties[0].last_updated_by;
        for (let i = 1; i < this.duties.length; i++) {
          const duty = this.duties[i];
          if ((0, _moment.default)(duty.last_updated_at) > (0, _moment.default)(dutyLastUpdatedAt)) {
            dutyLastUpdatedAt = duty.last_updated_at;
            dutyLastUpdatedBy = duty.last_updated_by;
          }
        }
        this.dutyLastUpdatedAt = `Last updated at: ${(0, _moment.default)(dutyLastUpdatedAt).format('YYYY-MM-DD hh:mm:ss')}`;
        this.dutyLastUpdatedBy = `Last updated By: ${dutyLastUpdatedBy}`;
      }
    },
    footerSummary() {
      // force adjust content with jQuery
      const summary = [];
      summary.push({
        cost: 0,
        manHour: 0
      });
      let totalCost = 0;
      let totalHr = 0;
      // const date = new Date()
      this.days.forEach((day, i) => {
        let dayCost = 0;
        let manHour = 0;
        this.users.forEach(user => {
          if (this.store && this.duties.find(duty => duty.store.uuid === this.store.uuid && duty.user.uuid === user.uuid && !duty.is_related)) {
            let UserDutyhour = 0;
            let UserDayCost = 0;
            const duties = this.getDuties(this.days[i], user.uuid);
            if (duties && this.store && duties[0].store.uuid === this.store.uuid) {
              if (user.roles.find(role => ['Staff (Full time)'].includes(role.name))) {
                const leaves = this.getLeaveHistory(this.days[i], user.uuid);
                if (user.monthlySalary) {
                  const dutyday = new Date(this.days[i]);
                  const month = dutyday.getMonth() + 1;
                  const year = dutyday.getFullYear();
                  const daysInMonth = new Date(year, month, 0).getDate();
                  UserDayCost = user.monthlySalary / daysInMonth;
                  duties.forEach(duty => {
                    const hour = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
                    UserDutyhour += hour;
                    // manHour += hour
                  });

                  if (leaves) {
                    leaves.forEach(leave => {
                      if (leave.storeCount && leave.storeCount > 1) {
                        UserDayCost = UserDayCost / leave.storeCount;
                      }
                      if (!leave.leave.has_pay) {
                        if (leave.leave.unit === 'full-day') {
                          UserDayCost = 0;
                        } else if (leave.leave.unit === 'half-day') {
                          if (leave.total === 0.5) {
                            UserDayCost = UserDayCost / 2;
                          } else {
                            UserDayCost = 0;
                          }
                        } else if (leave.leave.unit === 'hour') {
                          if (UserDutyhour > 0) {
                            UserDayCost = UserDayCost - UserDayCost * leave.total / UserDutyhour;
                          }
                        }
                      } else {
                        if (leave.leave.unit === 'half-day') {
                          if (leave.total === 0.5) {
                            UserDayCost = UserDayCost / 2;
                          }
                        }
                      }
                      if (leave.leave.unit === 'full-day') {
                        UserDutyhour = 0;
                      } else if (leave.leave.unit === 'half-day') {
                        if (leave.total === 0.5) {
                          UserDutyhour = UserDutyhour / 2;
                        } else {
                          UserDutyhour = 0;
                        }
                      } else if (leave.leave.unit === 'hour') {
                        if (UserDutyhour > 0) {
                          UserDutyhour -= leave.total;
                        }
                      }
                    });
                  }
                  dayCost += UserDayCost;
                  manHour += UserDutyhour;
                  // console.log(user.name, user.monthlySalary, leaves)
                } else {
                  duties.forEach(duty => {
                    const hour = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
                    const hourlyWage = duty.hourlyWage ? duty.hourlyWage : 0;
                    if (hour) {
                      if (!duty.effectiveLeave || duty.effectiveLeave.leave.has_pay) {
                        // both will be on company cost
                        manHour += hour;
                        dayCost += hour * hourlyWage;
                      } else if (!duty.effectiveLeave.leave.has_pay) {
                        // unpaid leave means not on company cost
                        // leaveunit = duty.effectiveLeave.leave.unit
                        if (duty.effectiveLeave.total === 0.5) {
                          // only add half
                          manHour += hour / 2;
                          dayCost += hour * hourlyWage / 2;
                        }
                      }
                    }
                  });
                }
              } else {
                duties.forEach(duty => {
                  const hour = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
                  const hourlyWage = duty.user.hourlyWage ? duty.user.hourlyWage : 0;
                  if (hour) {
                    manHour += hour;
                    dayCost += hour * hourlyWage;
                  }
                });
              }
            } else {
              const leaves = this.getLeaveHistory(this.days[i], user.uuid);
              if (leaves) {
                if (user.monthlySalary) {
                  const dutyday = new Date(this.days[i]);
                  const month = dutyday.getMonth() + 1;
                  const year = dutyday.getFullYear();
                  const daysInMonth = new Date(year, month, 0).getDate();
                  UserDayCost = user.monthlySalary / daysInMonth;
                  UserDutyhour = user.workingHoursPerDay;
                  leaves.forEach(leave => {
                    if (user.roles.find(role => ['Staff (Full time)'].includes(role.name))) {
                      if (leave.storeCount && leave.storeCount > 1) {
                        UserDayCost = UserDayCost / leave.storeCount;
                      }
                    }
                    if (!leave.leave.has_pay) {
                      if (leave.leave.unit === 'full-day') {
                        UserDayCost = 0;
                      } else if (leave.leave.unit === 'half-day') {
                        if (leave.total === 0.5) {
                          UserDayCost = UserDayCost / 2;
                        } else {
                          UserDayCost = 0;
                        }
                      } else if (leave.leave.unit === 'hour') {
                        if (UserDutyhour > 0) {
                          UserDayCost = UserDayCost - UserDayCost * leave.total / UserDutyhour;
                        }
                      }
                    } else {
                      if (leave.leave.unit === 'half-day') {
                        if (leave.total === 0.5) {
                          UserDayCost = UserDayCost / 2;
                        }
                      }
                    }
                    if (leave.leave.unit === 'full-day') {
                      UserDutyhour = 0;
                    } else if (leave.leave.unit === 'half-day') {
                      if (leave.total === 0.5) {
                        UserDutyhour = UserDutyhour / 2;
                      } else {
                        UserDutyhour = 0;
                      }
                    } else if (leave.leave.unit === 'hour') {
                      if (UserDutyhour > 0) {
                        UserDutyhour -= leave.total;
                      }
                    }
                  });
                }
                dayCost += UserDayCost;
                manHour += UserDutyhour;
                // console.log(user.name, user.monthlySalary, leaves)
              }
            }
          }
        });

        summary.push({
          cost: 0,
          manHour: 0
        });
        summary[i + 1].cost = dayCost.toFixed(0);
        summary[i + 1].manHour = manHour.toFixed(2);
        if (this.storeSales) {
          this.storeSales.forEach(storeSale => {
            if (storeSale.time === day) {
              summary[i + 1].percent = 0;
              if (storeSale.actualSale !== null) {
                summary[i + 1].sale = storeSale.actualSale.toFixed(0);
                if (storeSale.actualSale.toFixed(0)) {
                  if (!dayCost) {
                    summary[i + 1].percent = 0;
                  } else if (storeSale.actualSale) {
                    summary[i + 1].percent = (dayCost.toFixed(0) / storeSale.actualSale.toFixed(0) * 100).toFixed(2);
                  } else {
                    summary[i + 1].percent = 100;
                  }
                }
              } else if (storeSale.estimatedSale !== null) {
                summary[i + 1].est = storeSale.estimatedSale.toFixed(0);
                if (storeSale.estimatedSale.toFixed(0)) {
                  summary[i + 1].percent = (dayCost.toFixed(0) / storeSale.estimatedSale.toFixed(0) * 100).toFixed(2);
                }
              }
            }
          });
        }
        totalCost += dayCost;
        totalHr += manHour;
      });
      summary[0].cost = totalCost.toFixed(0);
      summary[0].manHour = totalHr.toFixed(2);
      var footer = document.getElementsByClassName('el-table__footer');
      var f0 = footer[0].getElementsByTagName('td'); // col.row0
      var f1 = footer[1].getElementsByTagName('td'); // col.row1

      for (let k = 0; k < summary.length; k++) {
        var col = f0; // .getElementsByTagName('td')
        var cell = col[k].getElementsByClassName('cell')[0];
        if (cell.length === 0) {
          break;
        }
        if (k === 0) {
          cell.innerHTML = '<div>Labor Cost</div><div>' + _i18n.default.t('Order.Total') + ': $' + summary[k].cost + '</div><div>' + summary[k].manHour + ' hr</div>';
          // col = f1.getElementsByTagName('td')
          cell = f1[k].getElementsByClassName('cell')[0];
          cell.innerHTML = '<div>Labor Cost</div><div>' + _i18n.default.t('Order.Total') + ': $' + summary[k].cost + '</div><div>' + summary[k].manHour + ' hr</div>';
        } else {
          var html = '<div>Cost: $' + summary[k].cost + '</div><div>(' + summary[k].manHour + ' hr)</div>';
          var color = '';
          var remark = '';
          var tooltip = '';
          if (summary[k].percent && summary[k].percent > 0 && parseFloat(summary[k].percent) > 30) {
            color = 'color:#F00;';
            tooltip = '<div class="tooltiptext">Please make sure labour cost is below 30%</div>';
            remark = '';
          } else if (summary[k].cost && !summary[k].sale) {
            if (this.user.roles.find(role => ['Admin', 'Operation Manager'].includes(role.name))) {
              remark = '<div><strong>Tgt. Sales: $' + Math.ceil((parseFloat(summary[k].cost) / 0.3).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</strong></div>';
            }
          } else {
            color = 'color:#00ab2e;';
            remark = '';
          }
          if (summary[k].sale) {
            if (this.user.roles.find(role => ['Admin', 'Operation Manager'].includes(role.name))) {
              html += '<div>Sales: $' + summary[k].sale + '</div><div class="tooltip" style="' + color + '">' + summary[k].percent + '%' + tooltip + '</div>' + remark;
            }
          } else if (summary[k].est) {
            html += '<div>Est: $' + summary[k].est + '</div><div style="' + color + '">' + summary[k].percent + '%</div>';
          } else {
            html += remark;
          }
          cell.innerHTML = html;
          // console.log('rendering footer', cell, k)
          // col = f1.getElementsByTagName('td')
          cell = f1[k].getElementsByClassName('cell')[0];
          cell.innerHTML = html;
          // console.log('f0 rendering footer', cell, k, cell.innerHTML)
        }
      }
    },

    getTotalCost(user) {
      if (user.roles.find(role => ['Staff (Full time)'].includes(role.name))) {
        if (user.monthlySalary) {
          return this.getTotalManDayWage(user).toFixed(0);
        } else {
          return '-';
        }
      } else {
        let costPerUser = 0;
        // const date = Date()
        this.days.forEach(day => {
          const duties = this.getDuties(day, user.uuid);
          if (duties) {
            duties.forEach(duty => {
              const time = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
              if (time) {
                costPerUser += time * duty.hourlyWage;
              }
            });
          }
        });
        return parseFloat(costPerUser.toFixed(2)).toFixed(0);
      }
    },
    getUserOTHr(user) {
      if (user && user.userLeaves) {
        var userLeave = user.userLeaves.filter(userLeave => {
          if (userLeave.leave.ot_count === 1) return userLeave;
        });
        if (userLeave.length > 0) {
          return 'Remain OT: ' + (userLeave[0].quota - userLeave[0].leave.leaveUsed) + ' Hr';
        }
      }
    },
    getTotalManDayWage(user) {
      // getTotalCost for Staff (Full time)
      let totalDayWage = 0;
      this.days.forEach(day => {
        const duties = this.getDuties(day, user.uuid);
        if (duties) {
          let dayCost = 0;
          const dutyday = new Date(day);
          const month = dutyday.getMonth() + 1;
          const year = dutyday.getFullYear();
          const daysInMonth = new Date(year, month, 0).getDate();
          dayCost = user.monthlySalary / daysInMonth;

          // const date = new Date() // Use the desired date here

          duties.forEach(duty => {
            const hour = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
            // const hourlyWage = duty.hourlyWage ? duty.hourlyWage : 0
            if (hour) {
              if (duty.effectiveLeave && !duty.effectiveLeave.leave.has_pay) {
                // unpaid, means not on company cost, half can be added/substract half same as inverted
                if (duty.effectiveLeave.leave.unit === 'full-day') {
                  dayCost = 0;
                } else if (duty.effectiveLeave.leave.unit === 'half-day') {
                  if (duty.effectiveLeave.total === 0.5) {
                    dayCost = dayCost / 2;
                  } else {
                    dayCost = 0;
                  }
                } else if (duty.effectiveLeave.leave.unit === 'hour') {
                  // this is using inverted
                  dayCost = dayCost - dayCost * duty.effectiveLeave.total / hour;
                }
              }
            }
          });
          totalDayWage += dayCost;
        }
      });
      return totalDayWage;
    },
    getTotalManHour(user) {
      let totalHr = 0;
      // const date = new Date()
      this.days.forEach(day => {
        const duties = this.getDuties(day, user.uuid);
        if (duties) {
          duties.forEach(duty => {
            let time = ((0, _moment.default)(duty.toTime) - (0, _moment.default)(duty.fromTime)) / 3600 / 1000;
            if (duty.effectiveLeave) {
              if (!duty.effectiveLeave.leave.has_pay) {
                if (duty.effectiveLeave.leave.unit === 'full-day') {
                  time = 0;
                } else if (duty.effectiveLeave.leave.unit === 'half-day') {
                  if (duty.effectiveLeave.total === 0.5) {
                    time = time / 2;
                  } else {
                    time = 0;
                  }
                } else if (duty.effectiveLeave.leave.unit === 'hour') {
                  time = time - duty.effectiveLeave.total;
                }
              }
            }
            if (time) {
              totalHr += time;
            }
          });
        }
      });
      return parseFloat(totalHr.toFixed(2));
    },
    selectWeek() {
      if (this.$refs.weekPicker) {
        this.$refs.weekPicker.focus();
      }
    },
    async pickedWeek(range) {
      var curr = new Date(range); // get current date
      if (curr.getDay() - 1 > 0) {
        curr = (0, _moment.default)(curr).subtract(curr.getDay() - 1, 'days').toDate();
      }
      var days = [];
      for (let i = 0; i < 7; i++) {
        var newDate = new Date(curr.getTime() + 60 * 60 * 24 * 1000 * i);
        var dd = String(newDate.getDate()).padStart(2, '0');
        var mm = String(newDate.getMonth() + 1).padStart(2, '0');
        var yyyy = newDate.getFullYear();
        days.push(yyyy + '-' + mm + '-' + dd);
      }
      this.days = days;
      this.hideOptions();
      // console.log('pickedWeek')
      this.cancelTokenFetch.abort();
      this.cancelTokenFetch = new AbortController();

      // console.log('getUsers')
      const promiseUser = this.getUsers();
      // console.log('fetchData')
      this.fetchData(false, promiseUser);
      // console.log('fetchData done')
    },

    getMonday(d) {
      d = new Date(d);
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    },
    mondayToWeek(date) {
      var days = [];
      for (let i = 0; i < 7; i++) {
        var newDate = new Date(date.getTime() + 60 * 60 * 24 * 1000 * i);
        var dd = String(newDate.getDate()).padStart(2, '0');
        var mm = String(newDate.getMonth() + 1).padStart(2, '0');
        var yyyy = newDate.getFullYear();
        days.push(yyyy + '-' + mm + '-' + dd);
      }
      return days;
    },
    getCurrentWeek() {
      var curr = new Date(); // get current date
      var monday = this.getMonday(curr);
      this.days = this.mondayToWeek(monday);
      // console.log('getCurrentWeek', this.days[0])
      this.week = this.days[0];

      // this.footerSummary()
    },

    getNextWeek() {
      var curr = new Date(this.days[6]); // get current date
      var newDate = new Date(curr.getTime() + 60 * 60 * 24 * 1000);
      var monday = this.getMonday(newDate);
      this.days = this.mondayToWeek(monday);
      this.week = this.days[0];
      this.hideOptions();
      // this.fetchData()
    },

    getPrevWeek() {
      var curr = new Date(this.days[0]); // get current date
      var newDate = new Date(curr.getTime() - 60 * 60 * 24 * 1000);
      var monday = this.getMonday(newDate);
      this.days = this.mondayToWeek(monday);
      this.week = this.days[0];
      this.hideOptions();
      // this.fetchData()
    },

    getCellClassName(cell) {
      const date = this.days[cell.columnIndex - 1];
      const classes = [];
      if (!this.listLoading && cell.columnIndex !== 0 && this.rosterConfirmations.find(rosterConfirmation => rosterConfirmation.date === date && rosterConfirmation.status === 'confirmed')) {
        classes.push('confirmed-cell');
      } else if (!this.listLoading && cell.columnIndex !== 0 && this.rosterConfirmations.find(rosterConfirmation => rosterConfirmation.date === date && rosterConfirmation.status === 'pending_approval')) {
        classes.push('pending-approval-cell');
      } else if (!this.listLoading && cell.columnIndex !== 0) {
        classes.push('unconfirmed-cell');
      }
      if (this.rosterConfirmations.find(rosterConfirmation => rosterConfirmation.date === date) && !this.user.roles.find(role => ['Operation Manager', 'Admin'].includes(role.name))) {
        classes.push('locked-cell');
      }
      if (cell.columnIndex === 6 || cell.columnIndex === 7) {
        classes.push('offday-cell');
      }
      if (cell.columnIndex) {
        const responseduties = this.responseduties.find(duty => duty.user.uuid === cell.row.uuid && duty.date === this.days[cell.columnIndex - 1]);
        // console.log('check responseduties', this.store, responseduties ? responseduties.store.uuid : '')
        if (responseduties) {
          // console.log('check store', this.store, responseduties.store.uuid)
          if (this.store && responseduties.user.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1 && responseduties.store.uuid !== this.store.uuid) {
            classes.push('not-related');
            return classes.join(' ');
          } else if (!this.store && responseduties.user.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1 && responseduties.is_related) {
            classes.push('not-related');
          }
        }
        const duty = this.duties.find(duty => duty.user.uuid === cell.row.uuid && duty.date === this.days[cell.columnIndex - 1]);
        // eslint no-cond-assign: 0
        if (duty && !cell.row.group) {
          if (cell.row.roles.findIndex(role => role.name === 'Store Manager') !== -1) {
            classes.push('store-manager');
          } else if (duty.role.name === 'Staff (Full time)' || cell.row.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1) {
            classes.push('full-time');
          } else {
            classes.push('part-time');
          }
        }
      }
      return classes.join(' ');
      // const user = this.users[cell.rowIndex]
      // return this.hasDuty(date, user.uuid) ? 'event-cell' : ''
    },

    getHeader(date) {
      var dt = new Date(date).toString();
      return dt.slice(0, 4).trim() + ', ' + this.ordinal_suffix_of(dt.slice(8, 10));
    },
    ordinal_suffix_of(i) {
      i = parseInt(i);
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return i + 'st';
      }
      if (j === 2 && k !== 12) {
        return i + 'nd';
      }
      if (j === 3 && k !== 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
    arraySpanMethod({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (row.group) {
        return [1, 8];
      }
      return [1, 1];
    },
    getParents(element, selector) {
      // console.log('called')
      var parents = [];
      var currentElement = element.parentElement;
      while (currentElement) {
        if (selector) {
          if (currentElement.matches(selector)) {
            parents.push(currentElement);
          }
        } else {
          parents.push(currentElement);
        }
        currentElement = currentElement.parentElement;
      }
      return parents;
    },
    showOptions(evt) {
      const buttons = evt.currentTarget.parentNode.querySelector('.button-group');
      if (buttons !== this.selectedOptions) {
        this.hideOptions();
      }
      var display = buttons.style.display === 'flex' ? 'none' : 'flex';
      buttons.style.display = display;
      this.selectedOptions = display === 'flex' ? buttons : null;
    },
    hideOptions() {
      if (this.selectedOptions != null) {
        this.selectedOptions.style.display = 'none';
        this.selectedOptions = null;
      }
    },
    startDrag(evt, duty) {
      if (this.getParents(evt.target, '.locked-cell').length > 0) {
        return;
      }
      this.hideOptions();
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      // evt.dataTransfer.setData('name', name)
      // evt.dataTransfer.setData('date', date)
      evt.dataTransfer.setData('dutyId', duty.uuid);
      // evt.target.style.opacity = '0.5'

      const td = this.getParents(evt.target, '.el-table__cell')[0];
      this.dragSource = td;
      td.style.opacity = '0.39';
      const cell = td.querySelector('.cell');
      const tdPreview = td.cloneNode(true); // td
      const divPreview = document.createElement('div');

      // Copy attributes (optional)
      for (const attr of tdPreview.attributes) {
        divPreview.setAttribute(attr.name, attr.value);
      }
      divPreview.innerHTML = tdPreview.innerHTML;
      const cellContainer = divPreview.querySelector('.cell-container');
      cellContainer.innerHTML = '';
      cellContainer.appendChild(evt.target.cloneNode(true));
      cell.appendChild(divPreview);
      evt.dataTransfer.setDragImage(divPreview, 0, 0);
      setTimeout(function () {
        cell.removeChild(divPreview);
      }, 0);
      /* setTimeout(() => {
        evt.target.style.opacity = '0.5'
      }, 0) */
    },

    endDrag() {
      if (this.dragSource) {
        // console.log(this.dragSource)
        // this.dragSource.style.opacity = '1'
        this.dragSource.style.opacity = '1';
      }
    },
    onDrop(evt, date, name) {
      const dutyId = evt.dataTransfer.getData('dutyId');
      const duty = this.duties.find(duty => duty.uuid === dutyId);
      const targetDuty = this.getDuty(date, name);
      this.hideOptions();
      this.dragSource.style.opacity = '1';
      if (date === null || name === null || targetDuty === duty || targetDuty) {
        this.dragSource.style.opacity = '1';
        return;
      }
      this.$delete(this.duties, this.duties.indexOf(duty));
      duty.name = name;
      duty.userId = name;
      duty.date = date;
      this.duties.push(duty);
      this.newDuty = duty;
      this.newDuty.fromTime = duty.date + ' ' + this.newDuty.fromHour + ':' + this.newDuty.fromMin + ':00';
      this.newDuty.toTime = duty.date + ' ' + this.newDuty.toHour + ':' + this.newDuty.toMin + ':00';
      // console.log({ ...duty }, { ...this.newDuty })
      this.updateDuty();
    },
    onDragEnter(evt, date, name) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('name', name);
      evt.dataTransfer.setData('date', date);
    },
    addz(num) {
      return num < 10 ? '0' + num : num;
    },
    getDuty(date, name) {
      var valObj = this.duties.filter(elem => {
        if (elem.date === date && elem.name === name) return elem;
      });
      return valObj[0];
    },
    getRelatedDuties(date, name) {
      /* if (!this.store) {
        return null
      } */
      var valObj = this.responseduties.filter(elem => {
        if (elem.date === date && elem.name === name && elem.is_related === true /*  && this.store.uuid !== elem.store.uuid */ && elem.user.roles.findIndex(role => role.name === 'Staff (Full time)') !== -1) return elem;
      });
      return valObj && valObj.length ? valObj : null;
    },
    getDuties(date, userId) {
      var valObj = this.duties.filter(elem => {
        if (elem.date === date && elem.user.uuid === userId && !elem.is_related) return elem;
      });
      // @TODO is unpublish / inactive ? isPublic
      if (!this.hasUnpublish) {
        this.hasUnpublish = valObj.findIndex(duty => duty.isPublic === false) !== -1;
      }
      return valObj && valObj.length ? valObj : null;
    },
    hasDuties(date, name) {
      var valObj = this.duties.filter(elem => {
        if (elem.date === date && elem.name === name) return elem;
      });
      return valObj && valObj.length > 0 ? true : null;
    },
    getLeaveHistory(date, name) {
      var valObj = this.leaves.filter(function (elem) {
        // console.log(elem, name, elem.leave.name, elem.date_from, elem.date_to, elem.date, date)
        if (elem.name === name && ['approved', 'pending', 'expired'].indexOf(elem.status) !== -1 && (0, _moment.default)(elem.date_from) <= (0, _moment.default)(date + ' 23:59:59') && (0, _moment.default)(elem.date_to) >= (0, _moment.default)(date + ' 00:00:00')) {
          return elem;
        }
        // if (elem.date === date && elem.name === name) return elem
      });

      return valObj && valObj.length ? valObj : null;
    },
    getDuration(valObj) {
      var str = valObj.fromHour + ':' + valObj.fromMin + ' - ' + valObj.toHour + ':' + valObj.toMin;
      if (valObj.nextDay) {
        str = valObj.fromHour + ':' + valObj.fromMin + ' - next day ' + valObj.toHour + ':' + valObj.toMin;
      }
      return str;
    },
    getLeaveHistoryInfo(day, valObj) {
      // valObj.total
      if (valObj.leave) {
        var period = '';
        var total = 0;
        var unit = 'day';
        if (valObj.leave.unit === 'full-day') {
          period = '1 ' + unit;
        } else if (valObj.leave.unit === 'half-day') {
          if (valObj.date_from === valObj.date_to) {
            total = valObj.total;
          } else {
            if (valObj.date_from === day + ' 00:00:00') {
              valObj.periodfrom === 'PM' ? total = 0.5 : total = 1;
            } else if (valObj.date_to === day + ' 00:00:00') {
              valObj.periodto === 'AM' ? total = 0.5 : total = 1;
            } else {
              total = 1;
            }
          }
          period = total + ' ' + unit;
        } else if (valObj.leave.unit === 'hour') {
          const checkingBeforeLunchHour = 11;
          const checkingAfterLunchHour = 15;
          const startingHour = '08';
          const endingHour = '18';
          unit = 'hour';
          const leave_from = valObj.date_from.split(' ')[1].split(':');
          const leave_to = valObj.date_to.split(' ')[1].split(':');
          if (valObj.date_from === valObj.date_to) {
            period = ' (' + leave_from[0] + ':' + leave_from[1] + ' - ' + leave_to[0] + ':' + leave_to[1] + ') \n' + valObj.total + ' ' + unit;
          } else {
            const date1 = (0, _moment.default)(valObj.date_from, 'YYYY-MM-DD');
            const date2 = (0, _moment.default)(valObj.date_to, 'YYYY-MM-DD');
            const diff = date2.diff(date1, 'days');
            const reaminDiff = valObj.total - (diff - 1) * 10;
            var leave_from_hour = leave_from[0];
            var leave_from_min = leave_from[1];
            var leave_to_hour = leave_to[0];
            var leave_to_min = leave_to[1];
            // console.log(diff, reaminDiff, valObj.date_to.split(' ')[0], day)
            if (valObj.date_from.split(' ')[0] === day) {
              if (parseInt(leave_from_hour) <= checkingBeforeLunchHour) {
                total = 10;
              } else if (parseInt(leave_from_hour) >= checkingAfterLunchHour && (reaminDiff !== 10 || parseInt(leave_from_hour) + Math.floor(reaminDiff / 2) > 23)) {
                total = reaminDiff - 10;
              } else {
                total = Math.floor(reaminDiff / 2);
              }
              // console.log(parseInt(leave_from_hour) + total)
              leave_to_hour = parseInt(leave_from_hour) + total;
            } else if (valObj.date_to.split(' ')[0] === day) {
              if (parseInt(leave_from_hour) <= checkingBeforeLunchHour) {
                total = reaminDiff - 10;
              } else if (parseInt(leave_from_hour) >= checkingAfterLunchHour && (reaminDiff !== 10 || parseInt(leave_from_hour) + Math.ceil(reaminDiff / 2) > 23)) {
                total = 10;
              } else {
                total = Math.ceil(reaminDiff / 2);
              }
              // console.log('last', valObj.date_to.split(' ')[0], day, (parseInt(leave_to_min) - total))
              leave_from_hour = this.addz(parseInt(leave_to_hour) - total);
            } else {
              leave_from_hour = startingHour;
              leave_to_hour = endingHour;
              total = 10;
            }
            // (moment(duty.toTime) - moment(duty.fromTime)) / 3600 / 1000
            period = ' (' + leave_from_hour + ':' + leave_from_min + ' - ' + leave_to_hour + ':' + leave_to_min + ') \n' + total + ' ' + unit;
          }
        }
        return period;
      } else {
        return '';
      }
    },
    getLeaveHistoryStatus(day, valObj) {
      return valObj.leave ? ' (' + valObj.status + ')' : '';
    },
    hasDuty(date, name) {
      var valObj = this.duties.filter(elem => {
        if (elem.date === date && elem.name === name) return elem;
      });
      if (valObj.length > 0) {
        return true;
      }
      return false;
    },
    getSummaries() {
      const summary = {};
      // let totalCost = 0
      // let totalHr = 0
      // disable calculate data => footerSummary(), only create the grids
      this.days.forEach((day, i) => {
        summary[i + 1] = ' ';
      });
      summary[0] = ' ';
      return summary;
    },
    checkFullTimeRole(roles) {
      if (roles !== undefined) {
        if (roles.find(role => ['Staff (Full time)'].includes(role.name))) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showAddDutyDialog(date, user) {
      this.newDuty = {
        date: date,
        userId: user.uuid,
        name: user.name,
        user: user,
        fromTime: null,
        toTime: null,
        roleId: ''
      };
      this.buttonText = 'Add';
      this.dialogFormVisible = true;
      this.hideOptions();
    },
    showAddLeaveDialog(date, user) {
      this.newLeavehistory = {
        date: date,
        user_id: user.uuid,
        leave_id: '',
        name: user.name,
        user: user,
        datefrom: date,
        dateto: date,
        status: 'pending'
      };
      this.buttonText = 'Add';
      this.dialogLeaveFormVisible = true;
      this.hideOptions();
    },
    showEditDutyDialog(duty) {
      this.newDuty = JSON.parse(JSON.stringify(duty));
      this.newDuty.fromTime = duty.date + ' ' + this.newDuty.fromHour + ':' + this.newDuty.fromMin + ':00';
      this.newDuty.toTime = duty.date + ' ' + this.newDuty.toHour + ':' + this.newDuty.toMin + ':00';
      this.newDuty.roleId = this.newDuty.role.id;
      this.newDuty.userId = this.newDuty.user.uuid;
      this.buttonText = 'Update';
      this.dialogFormVisible = true;
      this.hideOptions();
    },
    showEditLeaveDialog(leaveHistory, user) {
      // this.newLeavehistory =JSON.parse(JSON.stringify(leaveHistory))
      this.newLeavehistory = {
        id: leaveHistory.id,
        uuid: leaveHistory.uuid,
        date: leaveHistory.date_from,
        user_id: user.uuid,
        leave_id: leaveHistory.leave.uuid,
        name: user.name,
        user: user,
        total: leaveHistory.total,
        periodfrom: leaveHistory.periodfrom,
        periodto: leaveHistory.periodto,
        datefrom: leaveHistory.date_from,
        dateto: leaveHistory.date_to,
        status: leaveHistory.status,
        attachments: leaveHistory.attachments
      };
      // console.log(this.newLeavehistory)
      this.buttonText = 'Update';
      this.dialogLeaveFormVisible = true;
      this.hideOptions();
    },
    displayFromTime(data) {
      return '' + data.from_hour + ':' + (data.from_min > 9 ? '' : '0') + data.from_min;
    },
    displayToTime(data) {
      return '' + data.to_hour + ':' + (data.to_min > 9 ? '' : '0') + data.to_min;
    },
    deleteLeaveApply(leaveHistory) {
      this.listLoading = true;
      this.hideOptions();
      return _elementUi.MessageBox.confirm('Confirm to delete leave application?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _leave.deleteApplyHistory)(leaveHistory.uuid).catch(err => err.status === 404 ? Promise.resolve('already deleted') : Promise.reject(err)).then(() => {
        (0, _elementUi.Message)({
          message: 'Leave Application deleted',
          type: 'success',
          duration: 5 * 1000
        });
        const promiseUser = this.getUsers(true);
        this.listLoading = true;
        return this.fetchData(true, promiseUser);
        // this.$delete(this.duties, this.duties.indexOf(duty))
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
        this.listLoading = false;
      });
    },
    deleteDuty(duty) {
      this.listLoading = true;
      this.hideOptions();
      return _elementUi.MessageBox.confirm((duty.clockInTime || duty.clockOutTime ? 'This duty already has attendance, you\'ll need to re-create attendance manually.<br />' : '') + 'Confirm to delete duty?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => (0, _duty.deleteDuty)(duty.uuid).catch(err => err.status === 404 ? Promise.resolve('already deleted') : (0, _elementUi.Message)({
        dangerouslyUseHTMLString: true,
        message: (0, _errorParse.parseError)(err) || 'Delete failed, please retry.',
        type: 'error',
        duration: 5 * 1000
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Duty deleted',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
        // this.$delete(this.duties, this.duties.indexOf(duty))
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
        this.listLoading = false;
      });
    },
    async updateDuty() {
      const start = (0, _moment.default)(this.newDuty.fromTime);
      const end = (0, _moment.default)(this.newDuty.toTime);
      const param = {
        id: this.newDuty.uuid,
        date: this.newDuty.date,
        userId: this.newDuty.userId,
        storeId: this.$route.params.id,
        fromHour: start.format('HH'),
        fromMin: start.format('mm'),
        toHour: end.format('HH'),
        toMin: end.format('mm'),
        roleId: this.newDuty.roleId,
        nextDay: this.newDuty.nextDay
      };
      this.listLoading = true;
      return (0, _duty.editDuty)(param).then(response => {
        (0, _elementUi.Message)({
          message: 'Duty updated.',
          type: 'success',
          duration: 5 * 1000
        });
        const duty = response.data;
        var valObj = this.duties.filter(elem => {
          if (elem.uuid === duty.uuid) return elem;
        });
        this.$delete(this.duties, this.duties.indexOf(valObj[0]));
        duty.name = duty.user.uuid;
        this.duties.push(duty);
        this.dialogFormVisible = false;
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      }).finally(() => {
        this.listLoading = false;
      });
    },
    onComplete(duty) {
      if (this.newDuty.uuid) {
        var valObj = this.duties.filter(elem => {
          if (elem.uuid === duty.uuid) return elem;
        });
        this.$delete(this.duties, this.duties.indexOf(valObj[0]));
        duty.name = duty.user.uuid;
        this.duties.push(duty);
      } else {
        duty.name = duty.user.uuid;
        this.duties.push(duty);
      }
      this.hideOptions();
      this.listLoading = true;
      this.fetchData(true);
    },
    onLeaveComplete() {
      this.hideOptions();
      const promiseUser = this.getUsers(true);
      this.listLoading = true;
      this.fetchData(true, promiseUser);
    },
    async onDuplicatePicked(day) {
      const days = this.mondayToWeek(this.getMonday(new Date(day)));
      this.defaultDuplicateDatePicker = (0, _moment.default)(day).format('YYYY-MM-DD');
      this.duplicateWeek = null;
      return (0, _duty.bulkDuplicateDuty)({
        srcFromDate: days[0],
        srcToDate: days[6],
        targetFromDate: this.days[0],
        targetToDate: this.days[6],
        storeId: this.storeId
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties from ' + this.days[0] + ' to ' + this.days[6] + ' are duplicated to current week schedule.',
          type: 'success',
          duration: 5 * 1000
        });
        // this.getCurrentWeek()
        this.listLoading = true;
        return this.fetchData();
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      });
    },

    showCopyFromDatePicker() {
      if (this.$refs.duplicatePicker) {
        this.$refs.duplicatePicker.focus();
      }
    },
    calculateHour(fromHour, fromMin, toHour, toMin, nextDay) {
      const start = (0, _moment.default)();
      start.set({
        hour: fromHour,
        minute: fromMin
      });
      const end = (0, _moment.default)();
      end.set({
        hour: toHour,
        minute: toMin
      });
      if (nextDay) {
        end.add(15, 'days');
      }
      return _moment.default.duration(end.diff(start)).asHours();
    },
    checkManHour() {
      let notSame = '';
      if (this.duties.find(duty => {
        if (!duty.user.monthlySalary && duty.user.roles.find(role => ['Staff (Full time)'].includes(role.name))) {
          return true;
        }
        return false;
      })) {
        return _elementUi.MessageBox.confirm('Some Monthly Payment Staff has no monthly Salary set, Are you sure want to continue ?', 'Confirm', {
          confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
          cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
          type: 'warning'
        });
      } else if (this.duties.find(duty => {
        if (duty.user.workingHoursPerDay && duty.user.roles.find(role => ['Staff (Full time)'].includes(role.name)) && this.calculateHour(duty.fromHour, duty.fromMin, duty.toHour, duty.toMin, duty.nextDay) !== duty.user.workingHoursPerDay) {
          return true;
        }
        return false;
      })) {
        this.duties.find(duty => {
          if (duty.user.workingHoursPerDay && duty.user.roles.find(role => ['Staff (Full time)'].includes(role.name)) && this.calculateHour(duty.fromHour, duty.fromMin, duty.toHour, duty.toMin, duty.nextDay) !== duty.user.workingHoursPerDay) {
            notSame = notSame + duty.user.name + ': date: ' + duty.fromTime + ' - ' + duty.toTime + '<br />';
          }
        });
        if (notSame) {
          return _elementUi.MessageBox.confirm('Following Schedule are not same with preset working hour:<br />' + notSame + '<br />Are you sure want to continue?', 'Confirm', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: _i18n.default.t('PurchaseOrders.Confirm'),
            cancelButtonText: _i18n.default.t('PurchaseOrders.Cancel'),
            type: 'warning'
          });
        }
      }
      return Promise.resolve();
    },
    async submitAll() {
      return this.checkManHour().then(() => (0, _rosterConfirmation.bulkUpdateRosterConfirmation)({
        storeId: this.storeId,
        fromTime: this.days[0] + ' 00:00:00',
        toTime: this.days[6] + ' 23:59:59',
        status: 'pending_approval'
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties from ' + this.days[0] + ' to ' + this.days[6] + ' are submitted for approval now.',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      })).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    },
    async unSubmitAll() {
      return (0, _rosterConfirmation.bulkUpdateRosterConfirmation)({
        storeId: this.storeId,
        fromTime: this.days[0] + ' 00:00:00',
        toTime: this.days[6] + ' 23:59:59',
        status: 'draft'
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties from ' + this.days[0] + ' to ' + this.days[6] + ' are unsubmited.',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      });
    },

    async publishAll() {
      return this.checkManHour().then(() => (0, _rosterConfirmation.bulkUpdateRosterConfirmation)({
        storeId: this.storeId,
        fromTime: this.days[0] + ' 00:00:00',
        toTime: this.days[6] + ' 23:59:59',
        status: 'confirmed'
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties from ' + this.days[0] + ' to ' + this.days[6] + ' are published now.',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      }));
    },

    async unPublishAll() {
      return (0, _rosterConfirmation.bulkUpdateRosterConfirmation)({
        storeId: this.storeId,
        fromTime: this.days[0] + ' 00:00:00',
        toTime: this.days[6] + ' 23:59:59',
        status: 'pending_approval'
      }).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties from ' + this.days[0] + ' to ' + this.days[6] + ' are unpublished.',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
      }).catch(err => {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
          type: 'error',
          duration: 5 * 1000
        });
        // console.log(err)
      });
    },

    async clearAll() {
      return _elementUi.MessageBox.confirm('Confirm to delete all duty?', 'Confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => (0, _duty.bulkDeleteDuty)({
        storeId: this.storeId,
        fromTime: this.days[0] + ' 00:00:00',
        toTime: this.days[6] + ' 23:59:59'
      })).then(() => {
        (0, _elementUi.Message)({
          message: 'Duties deleted',
          type: 'success',
          duration: 5 * 1000
        });
        this.listLoading = true;
        return this.fetchData(true);
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
    }
  }
};
exports.default = _default;