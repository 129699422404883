"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _auth = require("@/utils/auth");
var _errorParse = require("@/utils/error-parse");
var _elementUi = require("element-ui");
var _moment = _interopRequireDefault(require("moment"));
var _leave = require("@/api/leave");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'LeaveDialog',
  props: {
    newLeavehistory: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minTime: '00:00',
      maxTime: '23:55',
      step: '00:05',
      periods: [{
        name: '上午',
        value: 'AM'
      }, {
        name: '下午',
        value: 'PM'
      }],
      history_statuss: [{
        name: '審核中',
        value: 'pending'
      }, {
        name: '批准',
        value: 'approved'
      }, {
        name: '拒絕',
        value: 'rejected'
      }],
      leaveUnit: '',
      ot_count: 0,
      timetoStart: '00:00',
      leaveUnitDisplay: '時長',
      remainHoildayDisplay: '',
      leaveUnitStep: 1,
      leaveMax: 0,
      leaveMin: 0,
      fileList: [],
      uploadDomain: process.env.VUE_APP_BASE_API,
      uploadHeader: {
        Authorization: 'Bearer ' + (0, _auth.getToken)(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      uploadData: {
        leave_history_id: ''
      },
      history: {
        id: 0,
        uuid: null,
        leave_id: null,
        user_id: null,
        datefrom: '',
        dateto: '',
        timefrom: '',
        timeto: '',
        periodfrom: 'AM',
        periodto: 'AM',
        total: 0,
        status: 'pending',
        remark: '',
        attachmentArrayId: [],
        attachments: []
      },
      userLeaves: [],
      rules: {
        // leave_id: { required: true, message: 'Leave type is required' }
        timefrom: [{
          required: true,
          validator: (rule, value, cb) => {
            try {
              // alert(this.leaveUnit)
              if (this.leaveUnit === 'hour' && (this.history.timefrom === '' || this.history.timefrom === null)) {
                cb(new Error('請墳寫開始時間'));
              } else {
                cb(undefined); // success
              }
            } catch (e) {
              cb(new Error('請墳寫開始時間'));
            }
          }
        }],
        timeto: [{
          required: true,
          validator: (rule, value, cb) => {
            try {
              // alert(this.history.timeto)
              if (this.leaveUnit === 'hour' && (this.history.timeto === '' || this.history.timeto === null)) {
                cb(new Error('請墳寫結束時間'));
              } else {
                cb(undefined); // success
              }
            } catch (e) {
              cb(new Error('請墳寫結束時間'));
            }
          }
        }],
        total: [{
          required: true,
          validator: (rule, value, cb) => {
            try {
              let userLeave = null;
              for (let i = 0; i < this.userLeaves.length; i++) {
                userLeave = this.userLeaves[i];
                if (userLeave.leave.uuid === this.history.leave_id) {
                  break;
                }
              }
              const quota = userLeave ? userLeave.quota : 0;
              if (parseFloat(this.history.total) < 0) {
                cb(new Error('Total can not be negative'));
              } else if (parseFloat(this.history.total) === 0) {
                cb(new Error('Total can not be zero'));
              } else if (quota > 0) {
                let leaveUsed = userLeave ? userLeave.leave.leaveUsed : 0;
                if (this.newLeavehistory.uuid !== undefined) {
                  // edit
                  leaveUsed = leaveUsed - parseFloat(this.newLeavehistory.total);
                }
                const remainLeave = Number(quota - leaveUsed);
                if (quota > 0 && remainLeave === 0) {
                  cb(new Error('Reach limit'));
                } else if (Number(this.history.total) > Number(remainLeave)) {
                  cb(new Error('Over quota: used ' + this.history.total + ', left ' + remainLeave));
                } else {
                  cb(undefined); // success
                }
              } else {
                cb(undefined); // success
              }
            } catch (e) {
              console.warn('error', e);
            }
          }
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['roles']),
    addTitle: {
      get() {
        return this.newLeavehistory.uuid === undefined ? 'Create Leave ' : 'Edit Leave ';
      }
    },
    buttonText: {
      get() {
        return this.newLeavehistory.uuid === undefined ? 'Add' : 'Edit';
      }
    },
    nameDisabled: {
      get() {
        return this.newLeavehistory.userId !== undefined;
      }
    },
    visible: {
      get() {
        return this.dialogFormVisible;
      },
      set(val) {
        this.$emit('update:dialogFormVisible', val);
      }
    }
  },
  /* watch: {
    newLeavehistory() {
      console.log('in')
      this.fetchData()
    }
  }, */
  async created() {
    // this.shiftResponse = []
    // console.log('created')
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const response = await (0, _leave.getUserLeaves)({
        fromTime: this.newLeavehistory.date,
        toTime: (0, _moment.default)(this.newLeavehistory.date).format('YYYY-MM-DD') + ' 23:59:59',
        user_id: this.newLeavehistory.user_id
      });
      if (!this.roles.find(role => ['Operation Manager', 'Admin'].includes(role.name))) {
        this.userLeaves = response.data.filter(userLeave => userLeave.leave.managed_by === 'manager');
      } else {
        this.userLeaves = response.data;
      }
      this.fileList = [];
      this.history.attachmentArrayId = [];
      if (this.newLeavehistory.uuid !== undefined) {
        // edit
        (0, _leave.getHistory)({
          apply_id: this.newLeavehistory.uuid,
          user_id: this.newLeavehistory.user_id,
          listAll: true
        }).then(responseHistory => {
          this.history.attachments = responseHistory.data[0].attachments;
          if (this.history.attachments) {
            this.history.attachments.forEach(attachment => {
              let path = attachment.path;
              try {
                path = path.split('/')[1];
              } catch (e) {
                console.warn(e);
              }
              this.fileList.push({
                id: attachment.id,
                name: path,
                url: attachment.url
              });
            });
          }
          this.uploadData.leave_history_id = this.newLeavehistory.id;
          if (responseHistory.data[0].leave.unit === 'hour') {
            this.history.timefrom = (0, _moment.default)(responseHistory.data[0].date_from).format('HH:mm');
            this.history.timeto = (0, _moment.default)(responseHistory.data[0].date_to).format('HH:mm');
          }
        });
        // console.log('get history')
      } else {
        this.uploadData.leave_history_id = '';
      }
      this.role = this.newLeavehistory.roleId;
      this.history.leave_id = this.newLeavehistory.leave_id;
      if (!this.newLeavehistory.leave_id && this.userLeaves.length === 1) {
        this.history.leave_id = this.userLeaves[0].leave.uuid;
      }
      this.history.datefrom = this.newLeavehistory.datefrom;
      this.history.dateto = this.newLeavehistory.dateto;
      this.history.user_id = this.newLeavehistory.user_id;
      this.history.total = this.newLeavehistory.total;
      this.history.status = this.newLeavehistory.status;
      if (this.newLeavehistory.periodfrom) {
        this.history.periodfrom = this.newLeavehistory.periodfrom;
      }
      if (this.newLeavehistory.periodto) {
        this.history.periodto = this.newLeavehistory.periodto;
      }
      // this.saveAsShift = false
      if (this.history.leave_id) {
        this.changeUnit();
      } else {
        this.remainHoildayDisplay = '';
      }
    },
    changeUnit() {
      this.timetoStart = '00:00';
      let userLeave = null;
      if (this.history.leave_id !== null && this.history.leave_id !== '') {
        this.leaveMin = 0;
        for (let i = 0; i < this.userLeaves.length; i++) {
          userLeave = this.userLeaves[i];
          if (userLeave.leave.uuid === this.history.leave_id) {
            this.ot_count = userLeave.leave.ot_count;
            if (userLeave.leave.ot_count === 1) {
              this.history.datefrom = this.newLeavehistory.datefrom;
              this.history.dateto = this.newLeavehistory.dateto;
            }
            this.leaveUnit = userLeave.leave.unit;
            switch (userLeave.leave.unit) {
              case 'full-day':
                this.leaveUnitDisplay = '時長/總日數';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
              case 'half-day':
                this.leaveUnitDisplay = '時長/總日數';
                this.leaveUnitStep = 0.5;
                this.leaveMin = 0.5;
                break;
              case 'hour':
                this.leaveUnitDisplay = '時長/總小時';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
              default:
                this.leaveUnitDisplay = '時長/總(天數/小時)';
                this.leaveUnitStep = 1;
                this.leaveMin = 1;
                break;
            }
            break;
          }
        }
      }
      this.calcTotal();
      this.remainHoildayDisplay = '';
      if (userLeave) {
        const quota = userLeave.quota;
        if (quota > 0) {
          let leaveUsed = userLeave.leave.leaveUsed;
          if (this.newLeavehistory.uuid !== undefined) {
            // edit
            if (this.newLeavehistory.leave_id === this.history.leave_id) {
              leaveUsed = leaveUsed - parseFloat(this.history.total);
            }
          }
          let unit = '日';
          if (userLeave.leave.unit === 'hour') {
            unit = '小時';
          }
          this.remainHoildayDisplay = '剩餘 ' + (quota - leaveUsed) + ' ' + unit;
        }
      }
    },
    calcTotal() {
      let userWorkingHoursPerDay = 10;
      const datefrom = (0, _moment.default)(this.history.datefrom).format('YYYY-MM-DD');
      const dateto = (0, _moment.default)(this.history.dateto).format('YYYY-MM-DD');
      const date1 = (0, _moment.default)(this.history.datefrom, 'YYYY-MM-DD');
      const date2 = (0, _moment.default)(this.history.dateto, 'YYYY-MM-DD');
      // var diff = this.calculateBusinessDays(datefrom, dateto)
      let diff = date2.diff(date1, 'days');
      if (this.leaveUnit === 'full-day') {
        if (diff > -1) {
          diff = diff + 1;
        }
      }
      if (this.leaveUnit === 'half-day') {
        if (this.history.periodfrom === this.history.periodto) {
          diff = diff + 0.5;
        } else if (this.history.periodfrom === 'AM' && this.history.periodto === 'PM') {
          diff = diff + 1;
        }
      }
      if (this.leaveUnit === 'hour') {
        if (this.history.timefrom !== '') {
          this.timetoStart = this.history.timefrom;
        }
        const time1 = (0, _moment.default)(datefrom + ' ' + this.history.timefrom + ':00');
        const time2 = (0, _moment.default)(dateto + ' ' + this.history.timeto + ':00');
        let timediff = time2.diff(time1, 'minutes') / 60;
        if (this.users) {
          let leaveUser = [];
          this.users.map(user => {
            if (user.uuid === this.history.user_id) {
              leaveUser = user;
              return user;
            }
          });
          if (leaveUser) {
            userWorkingHoursPerDay = parseFloat(leaveUser.workingHoursPerDay);
          }
        }
        if (timediff > 0) {
          if (diff === 0) {
            diff = timediff;
          } else {
            timediff = timediff - diff * 24;
            if (timediff < 0) {
              timediff = 0;
            }
            diff = diff * userWorkingHoursPerDay + timediff;
          }
        } else {
          diff = timediff;
        }
      }
      this.history.total = diff;
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return false;
        } else {
          this.createUpdateLeave();
        }
      });
    },
    createUpdateLeave() {
      this.loading = true;
      if (this.newLeavehistory.uuid) {
        const param = {
          leave_id: this.history.leave_id,
          user_id: this.history.user_id,
          datefrom: this.history.datefrom,
          dateto: this.history.dateto,
          timefrom: this.history.timefrom,
          timeto: this.history.timeto,
          periodfrom: this.history.periodfrom,
          periodto: this.history.periodto,
          total: this.history.total,
          status: this.history.status,
          remark: this.history.remark
        };
        param.uuid = this.newLeavehistory.uuid;
        (0, _leave.editApply)(param).then(response => {
          (0, _elementUi.Message)({
            message: 'Leave updated.',
            type: 'success',
            duration: 5 * 1000
          });
          const leave = response.data;
          this.$emit('complete', leave);
          this.$emit('update:dialogFormVisible', false);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Update failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          // console.log(err)
        }).finally(() => {
          this.loading = false;
        });
      } else {
        (0, _leave.createApply)(this.history).then(response => {
          (0, _elementUi.Message)({
            message: 'Leave application submitted.',
            type: 'success',
            duration: 5 * 1000
          });
          const leave = response.data;
          this.$emit('complete', leave);
          this.$emit('update:dialogFormVisible', false);
        }).catch(err => {
          (0, _elementUi.Message)({
            dangerouslyUseHTMLString: true,
            message: (0, _errorParse.parseError)(err) || 'Create failed, please retry.',
            type: 'error',
            duration: 5 * 1000
          });
          // console.log(err)
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    handlePreview(file) {
      window.open(file.url);
    },
    handleRemove(file, fileList) {
      if (file.id !== undefined) {
        (0, _leave.removeLeaveHistoryAttachment)(file.id);
      } else if (file.response[0].id !== undefined) {
        (0, _leave.removeLeaveHistoryAttachment)(file.response[0].id);
      } else {
        console.error(file);
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Do you confirm remove ${file.name}?`);
    },
    handleSuccess(response, file, fileList) {
      this.history.attachmentArrayId.push(response[0].id);
      if (this.newLeavehistory.uuid !== undefined) {
        // edit
        return this.fetchData();
      }
    },
    handleExceed() {
      this.$message.warning('Maximum upload 3 files.');
    }
  }
};
exports.default = _default;